<template>
  <div class="Lvideo">
      <!-- muted -->
      <!-- v-show="initPlay" -->
    <video
      ref="video"
      class="video"
      autoplay
      webkit-playsinline
      playsinline
      x5-playsinline
      loop
      preload="preload"
      name="media"
      :width="width"
      :height="height"
      :poster="imgSrc"
    >
      <source :src="videoSrc" type="video/mp4" />
    </video>
    <!-- <img
      v-show="!initPlay"
      ref="poster"
      class="poster"
      :src="imgSrc"
      :width="width"
      :height="height"
      @click="videoPlay"
    /> -->
  </div>
</template>

<script>
export default {
  name: "ZVideo",
  props: {
    height: {
      type: Number,
      default: 540,
    },
    width: {
      type: Number,
      default: 1920,
    },
    imgSrc: String,
    videoSrc: String,
  },
  mounted() {
    window.addEventListener('scroll', this.autoPlay);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.autoPlay);
  },
  methods: {
    autoPlay() {
      const { innerHeight: winHeight } = window;
      const { video } = this.$refs;
      const { height, top } = video.getBoundingClientRect();

      if (top < winHeight - 100 && top + height > 50) {
        video.paused && video.play();
      } else {
        !video.paused && video.pause();
      }
    },
    videoPlay() {
      const { video } = this.$refs;

      if (video.paused) {
        video.play();
        // initPlay.value = true;
      } else {
        video.pause();
        // initPlay.value = false;
      }
    }
  }
};

</script>



<style scoped lang="scss">
.Lvideo {
  .video {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

</style>
